import React, { useMemo, useState, useCallback } from "react";
import NextLink from "next/link";
import {
  Box,
  TableCell,
  Link,
  Typography,
  TableRow,
  useTheme,
  useMediaQuery,
} from "@material-ui/core";
import { makeStyles } from "@lib/themes";
import { format } from "d3-format";
import { addUnderscore, currencify } from "@lib/utils/formatting";
import NumberDynamic from "@components/NumberDynamic";
import SkeletonImg from "@components/SkeletonImg";
import {
  getDefaultGetTitles,
  columnsToHide,
  Title,
} from "@pages-components/TablePage";
import NumberFormat from "@components/NumberFormat";
import { ExchangesOverviewDataType } from "@pages-components/exchange/types";
import { Currency, Interval } from "@lib/types";
import { DEFAULT_INTERVALS } from "@lib/const";
import InfoBlock from "@components/InfoBlock";
import TableContainer from "@components/TableContainer";
import useSWR from "swr";
import fetcher from "@lib/fetcher";
import { titles } from "@pages/exchanges";
import { getExchangeSlug } from "@lib/utils";
import trackEvent, { GAEventCategory } from "@lib/ga";

interface ExchangesProps {
  interval: Interval;
  currency: Currency;
  activeCategory: number;
}

const API = "/api/exchanges/overview-list";

// const titles: Title[] = [
//   "#",
//   {
//     title: "Name",
//     sortBy: "exch_exchange_title",
//     props: { width: 200, align: "left" },
//   },
//   {
//     title: "Volume",
//     sortBy: ({ currency, interval }) => `volume_${interval}_${currency}`,
//     props: { width: 160, align: "center" },
//   },
//   {
//     title: "Volume Change",
//     sortBy: ({ currency, interval }) => `volume_change_${interval}_${currency}`,
//   },
//   { title: "No. of Pairs", sortBy: "exch_num_of_pairs" },
//   {
//     title: "Avg. Trade",
//     sortBy: ({ currency, interval }) =>
//       `average_transaction_${interval}_${currency}`,
//   },
//   {
//     title: "No. of Trades",
//     sortBy: ({ interval }) => `total_trades_${interval}`,
//   },
//   {
//     title: "No. of Trades Change",
//     sortBy: ({ interval }) => `trades_${interval}_change`,
//   },
//   {
//     title: "Asks Orderbook Depth",
//     sortBy: ({ currency }) => `ask_tot_${currency}`,
//   },
//   {
//     title: "Bids Orderbook Depth",
//     sortBy: ({ currency }) => `bid_tot_${currency}`,
//   },
// ];

const useStyles = makeStyles(() => ({
  wrapper: {
    width: "100%",
  },
  desktopChart: {
    overflow: "hidden",
    verticalAlign: "bottom",
    img: {
      display: "block",
    },
  },
  desktopChartImgWrap: {
    minHeight: 64,
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "center",
  },
  mobileChart: {
    width: "calc(100% + 32px)",
    height: 76,
    img: {
      display: "block",
    },
    paddingTop: 0,
    marginBottom: 4,
  },
}));

const Exchanges = ({ interval, currency, activeCategory }: ExchangesProps) => {
  const classes = useStyles();
  const [data, setData] = useState(undefined);
  const [page, setPage] = useState(0);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const dataCount = useMemo(() => {
    return data?.length ?? 0;
  }, [data]);

  useSWR(
    `${API}?${
      activeCategory === 2
        ? `filterBy=futures_volume_usd_${interval.slug}`
        : activeCategory === 3
        ? `filterBy=perp_volume_usd_${interval.slug}`
        : ""
    }&page=${page}`,
    fetcher,
    {
      onSuccess: (data) => {
        setData(data);
      },
    },
  );

  const volumeVal = (
    d: ExchangesOverviewDataType,
    currency: Currency,
    interval: Interval,
  ) => {
    switch (activeCategory) {
      case 0:
        return d[`mixed_volume_${currency.slug}_${interval.slug}`];
      case 1:
        return d[`volume_${interval.slug}_${currency.slug}_spot`];
      case 2:
        return d[`futures_volume_${currency.slug}_${interval.slug}`];
      case 3:
        return d[`perp_volume_${currency.slug}_${interval.slug}`];
    }
  };

  const volumeChangeVal = (
    d: ExchangesOverviewDataType,
    currency: Currency,
    interval: Interval,
  ) => {
    switch (activeCategory) {
      case 0:
        return d[`mixed_volume_change_${currency.slug}_${interval.slug}`];
      case 1:
        return d[`volume_change_${interval.slug}_${currency.slug}_spot`];
      case 2:
        return d[`futures_volume_${currency.slug}_change_${interval.slug}`];
      case 3:
        return d[`perp_volume_${currency.slug}_change_${interval.slug}`];
    }
  };

  const tradeChangeVal = (d: ExchangesOverviewDataType, interval: Interval) => {
    switch (interval.slug) {
      case DEFAULT_INTERVALS.DAY.slug:
        return d.trades_1d_change;
      case DEFAULT_INTERVALS.MONTH.slug:
        return d.trades_1m_change;
      case DEFAULT_INTERVALS.WEEK.slug:
        return d.trades_1w_change;
    }
  };

  const askTotVal = (d, currency: Currency) => {
    switch (activeCategory) {
      case 0:
        return d[`ask_100_${currency.slug}_all`];
      case 1:
        return d[`ask_tot_${currency.slug}_spot`];
      case 2:
        return d[`ask_100_${currency.slug}_futures`];
      case 3:
        return d[`ask_100_${currency.slug}_perpetual`];
    }
  };

  const bidTotVal = (d, currency: Currency) => {
    switch (activeCategory) {
      case 0:
        return d[`bid_100_${currency.slug}_all`];
      case 1:
        return d[`bid_tot_${currency.slug}_spot`];
      case 2:
        return d[`bid_100_${currency.slug}_futures`];
      case 3:
        return d[`bid_100_${currency.slug}_perpetual`];
    }
  };

  const numOfPairsVal = (d) => {
    switch (activeCategory) {
      case 0:
        return d[`num_pairs_all`];
      case 1:
        return d[`num_pairs_spot`];
      case 2:
        return d[`num_pairs_futures`];
      case 3:
        return d[`num_pairs_perpetual`];
    }
  };

  const getTitles = useMemo(() => {
    const hideColumn = activeCategory > 1 ? ["Volume Chart"] : [];
    return getDefaultGetTitles(columnsToHide(titles, hideColumn));
  }, [activeCategory]);

  const t = useMemo(() => {
    return getTitles({ interval: interval.slug, currency: currency.slug });
  }, [getTitles]);

  const renderRow = useCallback(
    (rowData, index, rowClassName) => {
      const row = rowData as ExchangesOverviewDataType;
      return (
        <TableRow
          key={row.exch_exchange_title}
          classes={{ root: rowClassName }}>
          <TableCell align="center">{index + 1}</TableCell>
          <TableCell>
            <NextLink
              href={`exchange/${addUnderscore(row.exch_exchange_title)}`}>
              <Box
                display="flex"
                alignItems="center"
                style={{ cursor: "pointer" }}
                onClick={() =>
                  trackEvent({
                    action: `Exchange ${row.exch_exchange_title}`,
                    category: GAEventCategory.HomePage,
                    label: `Exchange ${row.exch_exchange_title}`,
                  })
                }>
                <Box mr={1} height={24} width={24}>
                  <SkeletonImg
                    width={24}
                    height={24}
                    src={row.exch_logo_url}
                    skeletonProps={{ variant: "circular" }}
                    alt={`${row.exch_exchange_title} logo`}
                  />
                </Box>
                <Link
                  variant="body2"
                  color="inherit"
                  href={`exchange/${addUnderscore(row.exch_exchange_title)}`}>
                  {row.exch_exchange_title}
                </Link>
              </Box>
            </NextLink>
          </TableCell>
          <TableCell align="center">
            <NumberFormat
              value={volumeVal(row, currency, interval) as number}
              currency={currency.slug}
              format="bigNumber"
            />
          </TableCell>
          <TableCell align="center">
            <NumberDynamic
              boxProps={{ justifyContent: "center" }}
              dynamic={Math.sign(
                volumeChangeVal(row, currency, interval) as number,
              )}>
              {format(",.2f")(
                Math.abs(volumeChangeVal(row, currency, interval) as number),
              )}
              %
            </NumberDynamic>
          </TableCell>
          <TableCell align="center">{numOfPairsVal(row)}</TableCell>
          <TableCell align="center">
            {currencify(
              format(
                row[`average_transaction_${interval.slug}_${currency.slug}`] >
                  0.01
                  ? ",.2f"
                  : ",.3~r",
              )(row[`average_transaction_${interval.slug}_${currency.slug}`]),
              currency.value,
            )}
          </TableCell>
          <TableCell align="center">
            {format(",d")(row[`total_trades_${interval.slug}`])}
          </TableCell>
          <TableCell align="center">
            <NumberDynamic
              boxProps={{ justifyContent: "center" }}
              dynamic={Math.sign(tradeChangeVal(row, interval))}>
              {format(",.2f")(Math.abs(tradeChangeVal(row, interval)))}%
            </NumberDynamic>
          </TableCell>
          <TableCell align="center">
            <NumberFormat
              value={askTotVal(row, currency)}
              currency={currency.slug}
              format="bigNumber"
            />
          </TableCell>
          <TableCell align="center">
            <NumberFormat
              value={bidTotVal(row, currency)}
              currency={currency.slug}
              format="bigNumber"
            />
          </TableCell>
          {activeCategory <= 1 && (
            <TableCell
              align="center"
              padding="none"
              className={classes.desktopChart}>
              <Box className={classes.desktopChartImgWrap}>
                <SkeletonImg
                  width="100%"
                  height="100%"
                  src={`${process.env.WEBSITE}/chart-img/${getExchangeSlug(
                    row.exch_exchange_title,
                  )}-volume-${interval.slug}-usd.png`}
                  skeletonProps={{ variant: "rectangular" }}
                />
              </Box>
            </TableCell>
          )}
        </TableRow>
      );
    },
    [interval, currency, activeCategory],
  );

  const mobileRow = useCallback(
    (rowData, index) => {
      const row = rowData as ExchangesOverviewDataType;
      const volumeChart = activeCategory <= 1 && (
        <Box
          display="flex"
          width="100%"
          paddingTop={2}
          className={classes.mobileChart}>
          <InfoBlock
            title="Volume Chart"
            value={
              <div
                style={{
                  position: "relative",
                  left: -16,
                }}>
                <SkeletonImg
                  width="100%"
                  height={64}
                  src={`${process.env.WEBSITE}/chart-img/${getExchangeSlug(
                    row.exch_exchange_title,
                  )}-volume-${interval.slug}-usd.m.png`}
                  skeletonProps={{ variant: "rectangular" }}
                />
              </div>
            }
            display="flex"
            flexDirection="column"
            flex="100% 0 0"
            titleVariant="caption"
          />
        </Box>
      );

      const info = (
        <>
          <Box display="flex" alignItems="center">
            <Box mr={1} display="flex">
              <Typography
                color="textSecondary"
                variant="caption"
                fontWeight={600}>
                #{index + 1}
              </Typography>
            </Box>
            <NextLink
              href={`exchange/${addUnderscore(row.exch_exchange_title)}`}>
              <Box
                display="flex"
                alignItems="center"
                style={{ cursor: "pointer" }}
                onClick={() =>
                  trackEvent(
                    {
                      action: `Exchange ${row.exch_exchange_title}`,
                      category: GAEventCategory.HomePage,
                      label: `Exchange ${row.exch_exchange_title}`,
                    },
                    isMobile,
                  )
                }>
                <Box mr={1} height={24} width={24}>
                  <SkeletonImg
                    width={24}
                    height={24}
                    src={row.exch_logo_url}
                    skeletonProps={{ variant: "circular" }}
                    alt={`${row.exch_exchange_title} logo`}
                  />
                </Box>
                <Link
                  href={`exchange/${addUnderscore(row.exch_exchange_title)}`}
                  color="inherit">
                  <Typography variant="body1">
                    {row.exch_exchange_title}
                  </Typography>
                </Link>
              </Box>
            </NextLink>
          </Box>
          <Box display="flex" mt={2} mb={2}>
            <InfoBlock
              title="Volume"
              value={
                <Typography variant="body2">
                  <NumberFormat
                    value={volumeVal(row, currency, interval) as number}
                    currency={currency.slug}
                    format=",d"
                    shrinkForMobile={false}
                  />
                </Typography>
              }
              display="flex"
              flexDirection="column"
              flex="50% 0 0"
              titleVariant="caption"
            />
            <InfoBlock
              title="Volume Change"
              value={
                <NumberDynamic
                  dynamic={Math.sign(
                    volumeChangeVal(row, currency, interval) as number,
                  )}
                  typographyProps={{ variant: "body2" }}>
                  {format(",.2f")(
                    Math.abs(
                      volumeChangeVal(row, currency, interval) as number,
                    ),
                  )}
                  %
                </NumberDynamic>
              }
              display="flex"
              flexDirection="column"
              flex="50% 0 0"
              titleVariant="caption"
            />
          </Box>
        </>
      );

      return (
        <>
          {info}
          <Box display="flex">
            <InfoBlock
              title="No. of Pairs"
              value={
                <Typography variant="body2">{numOfPairsVal(row)}</Typography>
              }
              display="flex"
              flexDirection="column"
              flex="50% 0 0"
              titleVariant="caption"
            />
            <InfoBlock
              title="Avg. Trade"
              value={
                <Typography variant="body2">
                  {currencify(
                    format(
                      row[
                        `average_transaction_${interval.slug}_${currency.slug}`
                      ] > 0.01
                        ? ",.2f"
                        : ",.3~r",
                    )(
                      row[
                        `average_transaction_${interval.slug}_${currency.slug}`
                      ],
                    ),
                    currency.value,
                  )}
                </Typography>
              }
              display="flex"
              flexDirection="column"
              flex="50% 0 0"
              titleVariant="caption"
            />
          </Box>
          <Box display="flex" mt={2}>
            <InfoBlock
              title="No. of Trades"
              value={
                <Typography variant="body2">
                  {format(",d")(row[`total_trades_${interval.slug}`])}
                </Typography>
              }
              display="flex"
              flexDirection="column"
              flex="50% 0 0"
              titleVariant="caption"
            />
            <InfoBlock
              title="No. of Trades Change"
              value={
                <NumberDynamic
                  typographyProps={{ variant: "body2" }}
                  dynamic={Math.sign(tradeChangeVal(row, interval))}>
                  {format(",.2f")(Math.abs(tradeChangeVal(row, interval)))}%
                </NumberDynamic>
              }
              display="flex"
              flexDirection="column"
              flex="50% 0 0"
              titleVariant="caption"
            />
          </Box>
          <Box display="flex" mt={2} mb={2}>
            <InfoBlock
              title="Asks Orderbook Depth"
              value={
                <Typography variant="body2">
                  <NumberFormat
                    value={askTotVal(row, currency)}
                    currency={currency.slug}
                    format=",d"
                    shrinkForMobile={false}
                  />
                </Typography>
              }
              display="flex"
              flexDirection="column"
              flex="50% 0 0"
              titleVariant="caption"
            />
            <InfoBlock
              title="Bids Orderbook Depth"
              value={
                <Typography variant="body2">
                  <NumberFormat
                    value={bidTotVal(row, currency)}
                    currency={currency.slug}
                    format=",d"
                    shrinkForMobile={false}
                  />
                </Typography>
              }
              display="flex"
              flexDirection="column"
              flex="50% 0 0"
              titleVariant="caption"
            />
          </Box>
          {volumeChart}
        </>
      );
    },
    [interval, currency, activeCategory, isMobile],
  );

  return (
    <Box className={classes.wrapper}>
      <TableContainer
        data={data}
        dataCount={dataCount}
        loading={!data}
        ignoreTableClass
        onChangePage={(page) => setPage(page)}
        page={page}
        renderRow={renderRow}
        mobileItem={mobileRow}
        rowKey="exch_exchange_title"
        titles={t}
        mobileBreakpoint="lg"
        gaEventCategory={GAEventCategory.HomePage}
      />
    </Box>
  );
};

export default Exchanges;
