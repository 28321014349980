import { Tabs as BaseTabs } from "@material-ui/core";
import { makeStyles } from "@lib/themes";
import { ITheme } from "@lib/themes/types";

const useTabsStyles = makeStyles((theme: ITheme) => ({
  root: {
    // borderBottom: `1px solid ${theme.palette.divider}`,
    "& > a": {
      textDecoration: "none",
    },
    marginLeft: theme.spacing(-2),
    marginRight: theme.spacing(-2),
    minHeight: 40,
    [theme.breakpoints.down("sm")]: {
      "& .MuiTabs-flexContainer": {
        overflowY: "auto",
      },
    },
  },
  indicator: {
    display: "none",
    justifyContent: "center",
    backgroundColor: "transparent",
    height: 4,
    "& > span": {
      maxWidth: "calc(100% - 56px)",
      width: "100%",
      backgroundColor: theme.palette.primary.main,
      borderRadius: 120,
    },
  },
}));

export default function Tabs(props) {
  const tabsClasses = useTabsStyles();

  return (
    <BaseTabs
      TabIndicatorProps={{ children: <span /> }}
      classes={tabsClasses}
      aria-label="tabs"
      {...props}
    />
  );
}
