import { ITheme } from "@lib/themes/types";
import { Box } from "@material-ui/core";
import { makeStyles } from "@lib/themes";
import NumberFormat from "@components/NumberFormat";
import { Currency } from "@lib/types";
import { DEFAULT_CURRENCIES_ARRAY } from "@lib/const";

interface Props {
  numberOfAsk: number;
  numberOfBid: number;
  currency?: Currency;
}

const useStyles = makeStyles((theme: ITheme) => ({
  ask: {
    overflow: "hidden",
    display: "flex",
    justifyContent: "center",
    background: theme.palette.error.main,
    minHeight: theme.spacing(0.5),
    borderTopRightRadius: theme.spacing(0.5),
    borderBottomRightRadius: theme.spacing(0.5),
  },
  bid: {
    overflow: "hidden",
    display: "flex",
    justifyContent: "center",
    background: theme.palette.success.main,
    minHeight: theme.spacing(0.5),
    borderTopLeftRadius: theme.spacing(0.5),
    borderBottomLeftRadius: theme.spacing(0.5),
  },
  label: {
    color: theme.palette.text.primary,
    fontSize: theme.spacing(1.7),
  },
}));

const AskBidBar = ({
  numberOfAsk,
  numberOfBid,
  currency = DEFAULT_CURRENCIES_ARRAY[0],
}: Props) => {
  const classes = useStyles();

  if (!numberOfAsk || numberOfAsk <= 0 || !numberOfBid || numberOfBid <= 0)
    return null;

  return (
    <Box>
      <Box display="flex" justifyContent="space-between">
        <span className={classes.label}>
          <NumberFormat
            value={numberOfBid}
            currency={currency.slug}
            format="bigNumber"
            forceShortFormat
          />
        </span>
        <span className={classes.label}>
          <NumberFormat
            value={numberOfAsk}
            currency={currency.slug}
            format="bigNumber"
            forceShortFormat
          />
        </span>
      </Box>
      <Box display="flex" mt={0.3}>
        <Box flexGrow={numberOfBid} className={classes.bid}></Box>
        <Box flexGrow={numberOfAsk} className={classes.ask}></Box>
      </Box>
    </Box>
  );
};

export default AskBidBar;
