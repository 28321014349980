import React, { Fragment, useState, useMemo } from "react";
import {
  Typography,
  Box,
  TableCell,
  Link,
  useTheme,
  useMediaQuery,
} from "@material-ui/core";
import { makeStyles } from "@lib/themes";
import NextLink from "next/link";
import { format } from "d3-format";
import NumberDynamic from "@components/NumberDynamic";
import SkeletonImg from "@components/SkeletonImg";
import TablePage, {
  StaticPageProps,
  getDefaultGetTitles,
} from "@pages-components/TablePage";
import { getOverviewAssetsNum } from "@server-lib/assets";
import { getTopTrends } from "@server-lib/trends";
import { useMediaStyles } from "@lib/hooks/styles";
import { ITheme } from "@lib/themes/types";
import withAppProps from "@server-lib/with-app-props";
import NumberFormat from "@components/NumberFormat";
import { ASSETS_SEO } from "@lib/seo";
import {
  PAGE_SIZE,
  PREVIEW_PAGE_SIZE,
  DEFAULT_INTERVALS_ARRAY,
} from "@lib/const";
import { useMqttTopic, TOPICS } from "@lib/mqtt";
import { AppHead } from "@components/AppHead";
import { getOverview } from "@server-lib/assets";
// import { getOverviewDerivatives } from "@server-lib/assets";
import {
  Price,
  PriceChange,
  PairRateMsg,
  PriceChangeMsg,
} from "@lib/mqtt/helper-types";
import { titles, SortColumns } from "@pages-components/assets";
import AskBidBar from "@pages-components/asset/AskBidBar";
import InfoBlock from "@components/InfoBlock";
import Exchanges from "@components/Exchanges";
import { Currency } from "@lib/types";
import Tabs from "@components/Tabs";
import Tab from "@components/Tab";
import { useRouter } from "next/router";
import { addUnderscore } from "@lib/utils";
import { overviewCategories } from "@lib/utils";
import SelectCategory from "@components/SelectCategory";
import { useCallback } from "react";
import trackEvent, { GAEventCategory } from "@lib/ga";

const useStyles = makeStyles((theme: ITheme, props) => ({
  blur: {
    filter: "blur(2px)",
  },
  wrapper: {
    width: "100%",

    [theme.breakpoints.down("sm")]: {
      marginTop: "28px",
    },
    [theme.breakpoints.up("sm")]: {
      marginTop: theme.spacing(5),
    },
    [theme.breakpoints.down("md")]: {
      ".title-options": {
        marginBottom: theme.spacing(5),
      },
    },
  },
  desktopChart: {
    // width: 176,
    // height: 64,
    // display: "flex",
    overflow: "hidden",
    // boxSizing: "content-box",
    verticalAlign: "bottom",
    img: {
      display: "block",
    },
  },
  desktopChartImgWrap: {
    minHeight: 64,
    img: {
      height: "64px !important",
    },
  },
  mobileChart: {
    width: "calc(100% + 32px)",
  },
  descriptionBg: {
    position: "absolute",
    left: 0,
    width: "100%",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center top",
    [theme.breakpoints.down("sm")]: {
      top: 84,
      backgroundImage: "url(../home-bg-mobile.png)",
      backgroundSize: "554px 264px",
      height: 264,
    },
    [theme.breakpoints.up("sm")]: {
      top: 0,
      backgroundImage: "url(../home-bg.png)",
      backgroundSize: "1440px 444px",
      height: 442,
    },
  },
  description: {
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      paddingBottom: theme.spacing(5),
    },
    [theme.breakpoints.up("sm")]: {
      paddingTop: theme.spacing(5),
      paddingBottom: theme.spacing(15),
    },
    "& + div": {
      position: "relative",
    },
  },
  header: {
    marginBottom: theme.spacing(3),
  },
  subHeader: {
    maxWidth: 770,
    marginLeft: "auto",
    marginRight: "auto",
    [theme.breakpoints.down("sm")]: {
      marginBottom: theme.spacing(1),
    },
  },
  infoColExpanded: {
    marginBottom: theme.spacing(2),
    [theme.breakpoints.only("xs")]: {
      flex: "50% 0 0",
      "&:nth-last-child(-n+2)": {
        marginBottom: 0,
      },
    },
    [theme.breakpoints.up("sm")]: {
      flex: "33.33333% 0 0",
      "&:nth-last-child(-n+3)": {
        marginBottom: 0,
      },
    },
  },
  infoCol: {
    marginBottom: theme.spacing(2),
    flex: "33.33333%",
  },
  tabs: {
    [theme.breakpoints.down("md")]: {
      marginBottom: theme.spacing(3),
    },
    [theme.breakpoints.up("lg")]: {
      marginTop: 6,
    },
  },
  assetsTable: {
    display: props.tabIndex === 1 ? "none" : "",
    "& + .view-all-btn": {
      display: props.tabIndex === 1 ? "none" : "",
    },
  },
  category: {
    padding: "4px 12px",
    display: "flex",
    borderRadius: 6,
    cursor: "pointer",
    "&:hover": {
      background: theme.palette.picker.backgroundHover,
    },
    color: theme.palette.picker.text,
  },
  activeCategory: {
    background: `${theme.palette.picker.background} !important`,
    border: `1px solid ${theme.palette.picker.border} !important`,
    padding: "3px 11px",
    boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.08)",
    "&:hover": {
      background: `${theme.palette.picker.backgroundSelectedHover} !important`,
    },
    color: "#fff",
  },
  logo: {
    display: "block",
    [theme.breakpoints.down("md")]: {
      marginBottom: theme.spacing(2),
    },
  },
}));

interface Row {
  luner_coin_symbol: string;
  img_url: string;
  coin_title: string;
  luner_coin_title: string;
}

const TABS = ["Assets", "Exchanges"];

export default function AssetsOverview(props: StaticPageProps<Row>) {
  const mediaClasses = useMediaStyles();
  const theme = useTheme();
  const isDownSm: boolean = useMediaQuery(theme.breakpoints.down("sm"));
  const [tabIndex, setTabIndex] = useState(0);
  const classes = useStyles({ tabIndex });
  const router = useRouter();
  const [pageAssets, setPageAssets] = useState(
    props.initialData.map((v) => v.luner_coin_symbol),
  );
  const [interval, setInterval] = useState(DEFAULT_INTERVALS_ARRAY[0]);
  const [activeCategory, setActiveCategory] = useState(1);

  const priceSubs = useMemo(() => pageAssets.map((v) => ({ asset: v })), [
    pageAssets,
  ]);
  const priceChangeSubs = useMemo(
    () => pageAssets.map((v) => ({ asset: v, interval: interval.slug })),
    [pageAssets, interval],
  );

  const { values: prices } = useMqttTopic<Price, PairRateMsg>({
    batchDelay: 300,
    topic: TOPICS.ASSET_RATE,
    subs: priceSubs,
    extractKeyValue: (filling, msg) => {
      return {
        key: filling.asset,
        value: {
          usd: msg.usd_rate,
          eth: msg.eth_rate,
          btc: msg.btc_rate,
        },
      };
    },
  });

  const { values: priceChanges } = useMqttTopic<PriceChange, PriceChangeMsg>({
    batchDelay: 300,
    topic: TOPICS.PRICE_CHANGE,
    subs: priceChangeSubs,
    extractKeyValue: (filling, msg) => {
      return {
        key: filling.asset,
        value: {
          usd: msg.usd,
          eth: msg.eth,
          btc: msg.btc,
        },
      };
    },
  });

  const getTitles = useMemo(() => {
    return getDefaultGetTitles(titles, activeCategory);
  }, []);

  const renderExchanges = (currency: Currency) => (
    <Box display={tabIndex === 0 ? "none" : ""}>
      <Exchanges
        interval={interval}
        currency={currency}
        activeCategory={activeCategory}
      />
    </Box>
  );

  const tabs = useMemo(
    () => (
      <Box className={classes.tabs}>
        <Tabs
          value={tabIndex}
          defaultValue={tabIndex}
          onChange={(_, value) => setTabIndex(value)}>
          {TABS.map((title) => (
            <Tab
              key={title}
              label={title}
              onClick={() =>
                trackEvent(
                  {
                    action: title,
                    category: GAEventCategory.HomePage,
                    label: title,
                  },
                  isDownSm,
                )
              }
            />
          ))}
        </Tabs>
      </Box>
    ),
    [tabIndex, setTabIndex, isDownSm],
  );

  const handleChangeCategory = useCallback((index) => {
    setActiveCategory(index);
  }, []);

  const selectCategory = () => {
    return (
      <Box display="flex" mb={2}>
        <SelectCategory
          categories={overviewCategories}
          activeCategory={activeCategory}
          onChangeCategory={handleChangeCategory}
        />
      </Box>
    );
  };

  const coinVolume = (interval, currency, row) => {
    switch (activeCategory) {
      case 0:
        return row[
          `mixed_vol_global_deriv_spot_${currency.slug}_${interval.slug}`
        ] ? (
          <NumberFormat
            value={
              row[
                `mixed_vol_global_deriv_spot_${currency.slug}_${interval.slug}`
              ]
            }
            currency={currency.slug}
            format={isDownSm ? ",d" : "bigNumber"}
            shrinkForMobile={false}
          />
        ) : (
          <Typography variant="body2" className={mediaClasses.displayOnDesktop}>
            N/A
          </Typography>
        );
      case 1:
        return row[`volume_${interval.slug}_${currency.slug}_spot`] ? (
          <NumberFormat
            value={row[`volume_${interval.slug}_${currency.slug}_spot`]}
            currency={currency.slug}
            format={isDownSm ? ",d" : "bigNumber"}
            shrinkForMobile={false}
          />
        ) : (
          <Typography variant="body2" className={mediaClasses.displayOnDesktop}>
            N/A
          </Typography>
        );
      case 2:
        return row[`futures_volume_${currency.slug}_${interval.slug}`] ? (
          <NumberFormat
            value={row[`futures_volume_${currency.slug}_${interval.slug}`]}
            currency={currency.slug}
            format={isDownSm ? ",d" : "bigNumber"}
            shrinkForMobile={false}
          />
        ) : (
          <Typography variant="body2" className={mediaClasses.displayOnDesktop}>
            N/A
          </Typography>
        );
      case 3:
        return row[`perp_volume_${currency.slug}_${interval.slug}`] ? (
          <NumberFormat
            value={row[`perp_volume_${currency.slug}_${interval.slug}`]}
            currency={currency.slug}
            format={isDownSm ? ",d" : "bigNumber"}
            shrinkForMobile={false}
          />
        ) : (
          <Typography variant="body2" className={mediaClasses.displayOnDesktop}>
            N/A
          </Typography>
        );
    }
  };

  const coinVolumeChange = (interval, currency, row) => {
    switch (activeCategory) {
      case 0:
        return row[
          `mixed_vol_change_global_deriv_spot_${currency.slug}_${interval.slug}`
        ] ? (
          <NumberDynamic
            boxProps={{
              paddingRight: "24px",
            }}
            dynamic={Math.sign(
              row[
                `mixed_vol_change_global_deriv_spot_${currency.slug}_${interval.slug}`
              ],
            )}
            typographyProps={isDownSm && { variant: "body2" }}>
            {format(",.2f")(
              Math.abs(
                row[
                  `mixed_vol_change_global_deriv_spot_${currency.slug}_${interval.slug}`
                ],
              ),
            )}
            %
          </NumberDynamic>
        ) : (
          <Typography variant="body2" className={mediaClasses.displayOnDesktop}>
            N/A
          </Typography>
        );
      case 1:
        return row[`volumen_change_${interval.slug}_${currency.slug}_spot`] ? (
          <NumberDynamic
            boxProps={{
              paddingRight: "24px",
            }}
            dynamic={Math.sign(
              row[`volumen_change_${interval.slug}_${currency.slug}_spot`],
            )}
            typographyProps={isDownSm && { variant: "body2" }}>
            {format(",.2f")(
              Math.abs(
                row[`volumen_change_${interval.slug}_${currency.slug}_spot`],
              ),
            )}
            %
          </NumberDynamic>
        ) : (
          <Typography variant="body2" className={mediaClasses.displayOnDesktop}>
            N/A
          </Typography>
        );
      case 2:
        return row[
          `futures_volume_${currency.slug}_change_${interval.slug}`
        ] ? (
          <NumberDynamic
            boxProps={{
              paddingRight: "24px",
            }}
            dynamic={Math.sign(
              row[`futures_volume_${currency.slug}_change_${interval.slug}`],
            )}
            typographyProps={isDownSm && { variant: "body2" }}>
            {format(",.2f")(
              Math.abs(
                row[`futures_volume_${currency.slug}_change_${interval.slug}`],
              ),
            )}
            %
          </NumberDynamic>
        ) : (
          <Typography variant="body2" className={mediaClasses.displayOnDesktop}>
            N/A
          </Typography>
        );
      case 3:
        return row[`perp_volume_${currency.slug}_change_${interval.slug}`] ? (
          <NumberDynamic
            boxProps={{
              paddingRight: "24px",
            }}
            dynamic={Math.sign(
              row[`perp_volume_${currency.slug}_change_${interval.slug}`],
            )}
            typographyProps={isDownSm && { variant: "body2" }}>
            {format(",.2f")(
              Math.abs(
                row[`perp_volume_${currency.slug}_change_${interval.slug}`],
              ),
            )}
            %
          </NumberDynamic>
        ) : (
          <Typography variant="body2" className={mediaClasses.displayOnDesktop}>
            N/A
          </Typography>
        );
    }
  };

  const bidAndAskVol = (interval, currency, row) => {
    switch (activeCategory) {
      case 0:
        return {
          ask: row[`ask_1_all_${currency.slug}`],
          bid: row[`bid_1_all_${currency.slug}`],
        };
      case 1:
        return {
          ask: row[`ask_1_spot_${currency.slug}`],
          bid: row[`bid_1_spot_${currency.slug}`],
        };
      case 2:
        return {
          ask: row[`ask_1_futures_${currency.slug}`],
          bid: row[`bid_1_futures_${currency.slug}`],
        };
      case 3:
        return {
          ask: row[`ask_1_perpetual_${currency.slug}`],
          bid: row[`bid_1_perpetual_${currency.slug}`],
        };
    }
  };

  const renderStickyHeaderContent = () => (
    <img src="/logo.png" className={classes.logo} height="40" width="116" />
  );

  return (
    <>
      <AppHead
        title={ASSETS_SEO.title}
        description={ASSETS_SEO.secondDescription}
        previewImage={""}>
        <title>{ASSETS_SEO.title}</title>
        <meta name="description" content={ASSETS_SEO.description} />
      </AppHead>
      <div className={classes.wrapper}>
        <Box className={classes.descriptionBg} />
        <Box className={classes.description} textAlign="center">
          <Typography
            variant="h1"
            color="textPrimary"
            className={classes.header}>
            Data Driven Decisions for Crypto Holders
          </Typography>
          <Typography
            component="p"
            variant="h4"
            color="textPrimary"
            className={classes.subHeader}>
            Make informed decisions about your crypto holdings using enhanced
            data from the top exchanges. Access real-time data, historical data,
            liquidity charts, comparison and more.
          </Typography>
        </Box>

        <TablePage<Row, SortColumns>
          {...props}
          onNewData={(data) => {
            setPageAssets(data.map((v) => v.luner_coin_symbol));
          }}
          onIntervalChange={(interval) => {
            setInterval(interval);
          }}
          subChildrensAboveTable={selectCategory}
          filterBy={
            activeCategory === 2
              ? `futures_volume_usd_${interval.slug}`
              : activeCategory === 3
              ? `perp_volume_usd_${interval.slug}`
              : ""
          }
          pageTitle=""
          pageSubtitle=""
          api="/api/assets/overview-list"
          getTitles={getTitles}
          rowKey="luner_coin_title"
          useIntervals
          prices={prices}
          pricesChange={priceChanges}
          isFixedLayout={props.dataCount > props.pageSize}
          serverSideSorting={false}
          homePage
          rowContent={(row, { index, currency, interval }) => {
            const priceCange =
              priceChanges.has(row.luner_coin_symbol) &&
              priceChanges.get(row.luner_coin_symbol)[currency.slug];

            return (
              <Fragment>
                <TableCell>{index + 1}</TableCell>
                <TableCell>
                  <NextLink
                    href={`asset/${addUnderscore(row.luner_coin_title)}`}>
                    <Box
                      display="flex"
                      alignItems="center"
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        trackEvent({
                          action: `Asset ${row.luner_coin_title}`,
                          category: GAEventCategory.HomePage,
                          label: `Asset ${row.luner_coin_title}`,
                        })
                      }>
                      <Box mr={1} height={24} width={24}>
                        <SkeletonImg
                          width={24}
                          height={24}
                          src={row.img_url}
                          skeletonProps={{ variant: "circular" }}
                          alt={`${row.coin_title} (${row.luner_coin_symbol}) logo`}
                        />
                      </Box>
                      <Link
                        href={`asset/${addUnderscore(row.luner_coin_title)}`}
                        color="inherit"
                        style={{ minWidth: 0 }}>
                        <Typography
                          variant="body1"
                          noWrap
                          overflow="hidde"
                          textOverflow="ellipsis"
                          maxWidth="100%">
                          {row.luner_coin_title}
                          <Typography
                            variant="body2"
                            color="textSecondary"
                            component="span"
                            pl={0.5}>
                            ({row.luner_coin_symbol})
                          </Typography>
                        </Typography>
                      </Link>
                    </Box>
                  </NextLink>
                </TableCell>
                <TableCell>
                  {prices.has(row.luner_coin_symbol) ? (
                    <NumberFormat
                      value={prices.get(row.luner_coin_symbol)[currency.slug]}
                      format=",.5~r"
                      currency={currency.value}
                      changeAnimationEnabled={true}
                    />
                  ) : (
                    <NumberFormat
                      key={`mqtt-${row.luner_coin_symbol}`}
                      value={row[currency.slug + "_price"]}
                      format=",.5~r"
                      currency={currency.value}
                      changeAnimationEnabled={true}
                      className={classes.blur}
                    />
                  )}
                </TableCell>
                <TableCell>
                  {priceCange && (
                    <NumberFormat
                      dynamic={priceCange}
                      value={priceCange}
                      format="percent"
                    />
                  )}
                </TableCell>
                <TableCell>
                  {row[`marketcap_${currency.slug}`] && (
                    <NumberFormat
                      value={row[`marketcap_${currency.slug}`]}
                      currency={currency.slug}
                      format="bigNumber"
                    />
                  )}
                </TableCell>
                <TableCell>{coinVolume(interval, currency, row)}</TableCell>
                <TableCell>
                  {coinVolumeChange(interval, currency, row)}
                </TableCell>
                <TableCell>
                  {bidAndAskVol(interval, currency, row)?.ask &&
                  bidAndAskVol(interval, currency, row)?.ask != 0 &&
                  bidAndAskVol(interval, currency, row)?.bid &&
                  bidAndAskVol(interval, currency, row)?.bid ? (
                    <AskBidBar
                      numberOfAsk={bidAndAskVol(interval, currency, row).ask}
                      numberOfBid={bidAndAskVol(interval, currency, row).bid}
                      currency={currency}
                    />
                  ) : (
                    <Typography
                      variant="body2"
                      className={mediaClasses.displayOnDesktop}>
                      N/A
                    </Typography>
                  )}
                </TableCell>
                <TableCell padding="none" className={classes.desktopChart}>
                  <Box className={classes.desktopChartImgWrap}>
                    <SkeletonImg
                      width="100%"
                      height="100%"
                      src={`${process.env.WEBSITE}/chart-img/${
                        priceCange > 0 ? "g" : "r"
                      }-${row.luner_coin_symbol}-${interval.slug}-${
                        currency.slug
                      }.png`}
                      skeletonProps={{ variant: "rectangular" }}
                      alt={`${
                        row.coin_title
                      } last ${interval.title.toLowerCase()} price chart (${
                        currency.title
                      })`}
                    />
                  </Box>
                </TableCell>
              </Fragment>
            );
          }}
          mobileItem={(row, { index, currency, interval }) => {
            const priceCange =
              priceChanges.has(row.luner_coin_symbol) &&
              priceChanges.get(row.luner_coin_symbol)[currency.slug];

            return (
              <>
                <Box display="flex" alignItems="center" minHeight={40} mb={1}>
                  <Box mr={1} display="flex">
                    <Typography color="textSecondary" variant="body2">
                      #{index + 1}
                    </Typography>
                  </Box>
                  <NextLink
                    href={`asset/${addUnderscore(row.luner_coin_title)}`}>
                    <Box
                      display="flex"
                      alignItems="center"
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        trackEvent(
                          {
                            action: `Asset ${row.luner_coin_title}`,
                            category: GAEventCategory.HomePage,
                            label: `Asset ${row.luner_coin_title}`,
                          },
                          isDownSm,
                        )
                      }>
                      <Box mr={1} height={24} width={24}>
                        <SkeletonImg
                          width={24}
                          height={24}
                          src={row.img_url}
                          skeletonProps={{ variant: "circular" }}
                          alt={`${row.coin_title} (${row.luner_coin_symbol}) logo`}
                        />
                      </Box>
                      <Link
                        href={`asset/${addUnderscore(row.luner_coin_title)}`}
                        color="inherit">
                        <Typography
                          variant="body1"
                          className={mediaClasses.displayOnMobile}>
                          {row.coin_title}
                        </Typography>
                      </Link>
                    </Box>
                  </NextLink>
                </Box>
                <Box display="flex" flexWrap="wrap">
                  <InfoBlock
                    title="Price"
                    value={
                      <Typography variant="body2">
                        {prices.has(row.luner_coin_symbol) ? (
                          <NumberFormat
                            key={`placeholder-${row.luner_coin_symbol}`}
                            value={
                              prices.get(row.luner_coin_symbol)[currency.slug]
                            }
                            format=",.5~r"
                            currency={currency.value}
                            changeAnimationEnabled={true}
                          />
                        ) : (
                          <NumberFormat
                            key={`mqtt-${row.luner_coin_symbol}`}
                            value={row[currency.slug + "_price"]}
                            format=",.5~r"
                            currency={currency.value}
                            changeAnimationEnabled={false}
                            className={classes.blur}
                          />
                        )}
                      </Typography>
                    }
                    display="flex"
                    flexDirection="column"
                    className={classes.infoColExpanded}
                    titleVariant="caption"
                  />
                  <InfoBlock
                    title="Price Change"
                    value={
                      <>
                        {priceCange && (
                          <NumberFormat
                            dynamic={priceCange}
                            value={priceCange}
                            format="percent"
                          />
                        )}
                      </>
                    }
                    display="flex"
                    flexDirection="column"
                    className={classes.infoColExpanded}
                    titleVariant="caption"
                  />
                  <InfoBlock
                    title="Volume"
                    value={
                      <Typography variant="body2">
                        {coinVolume(interval, currency, row)}
                      </Typography>
                    }
                    display="flex"
                    flexDirection="column"
                    className={classes.infoColExpanded}
                    titleVariant="caption"
                  />
                  <InfoBlock
                    title="Volume Change"
                    value={coinVolumeChange(interval, currency, row)}
                    display="flex"
                    flexDirection="column"
                    className={classes.infoColExpanded}
                    titleVariant="caption"
                  />
                  <InfoBlock
                    title="Market Cap"
                    value={
                      <Typography variant="body2">
                        {row[`marketcap_${currency.slug}`] && (
                          <NumberFormat
                            value={row[`marketcap_${currency.slug}`]}
                            currency={currency.slug}
                            format=",d"
                            shrinkForMobile={false}
                          />
                        )}
                      </Typography>
                    }
                    display="flex"
                    flexDirection="column"
                    className={classes.infoColExpanded}
                    titleVariant="caption"
                  />
                  <InfoBlock
                    title="Bids vs Asks (1% depth)"
                    value={
                      bidAndAskVol(interval, currency, row)?.ask &&
                      bidAndAskVol(interval, currency, row)?.ask != 0 &&
                      bidAndAskVol(interval, currency, row)?.bid &&
                      bidAndAskVol(interval, currency, row)?.bid ? (
                        <AskBidBar
                          numberOfAsk={
                            bidAndAskVol(interval, currency, row).ask
                          }
                          numberOfBid={
                            bidAndAskVol(interval, currency, row).bid
                          }
                          currency={currency}
                        />
                      ) : (
                        <Typography
                          variant="body2"
                          className={mediaClasses.displayOnDesktop}>
                          N/A
                        </Typography>
                      )
                    }
                    display="flex"
                    flexDirection="column"
                    className={classes.infoColExpanded}
                    titleVariant="caption"
                  />
                </Box>
                <Box
                  display="flex"
                  width="100%"
                  paddingTop={2}
                  className={classes.mobileChart}>
                  <InfoBlock
                    title="Price Chart"
                    value={
                      <div
                        style={{
                          position: "relative",
                          left: -16,
                        }}>
                        <SkeletonImg
                          width="100%"
                          height={64}
                          src={`${process.env.WEBSITE}/chart-img/${
                            priceCange > 0 ? "g" : "r"
                          }-${row.luner_coin_symbol}-${interval.slug}-${
                            currency.slug
                          }.m.png`}
                          skeletonProps={{ variant: "rectangular" }}
                          alt={`${
                            row.coin_title
                          } last ${interval.title.toLowerCase()} price chart (${
                            currency.title
                          })`}
                        />
                      </div>
                    }
                    display="flex"
                    flexDirection="column"
                    flex="100% 0 0"
                    titleVariant="caption"
                  />
                </Box>
              </>
            );
          }}
          mobileBreakpoint="lg"
          childrensAboveTable={renderExchanges}
          titleOptionsTitleContent={tabs}
          tableContainerClassName={classes.assetsTable}
          onViewAllBtnClick={(e) => {
            router.push(
              {
                pathname: "/assets",
                query: { scroll: e.currentTarget.getBoundingClientRect().top },
              },
              undefined,
              { shallow: true },
            );
          }}
          showStickyHeader
          stickyHeaderContent={renderStickyHeaderContent}
          gaEventCategory={GAEventCategory.HomePage}
        />
      </div>
    </>
  );
}

export const getStaticProps = withAppProps(async () => {
  const dataCount = await getOverviewAssetsNum();
  const pagesCount = Math.ceil(dataCount / PAGE_SIZE);
  const data = await getOverview(0, PREVIEW_PAGE_SIZE);
  const trends = await getTopTrends();

  return {
    props: {
      initialData: data,
      dataCount,
      initialTrends: trends,
      pagesCount,
      pageSize: PAGE_SIZE,
    },
    revalidate: 60 * 5,
  };
});

// export const getStaticProps = withAppProps(async (context) => {
//   const data = await getOverview(0)
//   const dataCount = await getOverviewAssetsNum()
//   const trends = await getTopTrends()
//
//   return {
//     props: {
//       initialData: data,
//       dataCount,
//       initialTrends: trends,
//     },
//     revalidate: 60 * 5,
//   }
// })
