import { extractSortColumns } from "@pages-components/TablePage";
import { Title } from "@pages-components/TablePage";

export const titles: Title[] = [
  "#",
  { title: "Name", sortBy: "coin_title", props: { width: 180 } },
  { title: "Price", sortBy: ({ currency }) => `price` },
  {
    title: "Change",
    sortBy: ({ currency, interval }) => `price_change`,
    props: { width: 100 },
  },
  {
    title: "Market Cap.",
    sortBy: ({ currency }) => `marketcap_${currency}`,
    props: { width: 130 },
  },
  {
    title: "Volume",
    sortBy: ({ currency, interval, activeCategory }) => {
      switch (activeCategory) {
        case 0:
          return `mixed_vol_global_deriv_spot_${currency}_${interval}`;
        case 1:
          return `volume_${interval}_${currency}_spot`;
        case 2:
          return `futures_volume_${currency}_${interval}`;
      }
    },
  },
  {
    title: "Volume Change",
    sortBy: ({ currency, interval, activeCategory }) => {
      switch (activeCategory) {
        case 0:
          return `mixed_vol_change_global_deriv_spot_${currency}_${interval}`;
        case 1:
          return `volumen_change_${interval}_${currency}_spot`;
        case 2:
          return `futures_volume_${currency}_change_${interval}`;
      }
    },
    props: { width: 150 },
  },
  // {
  //   title: "ATH-ATL Position",
  //   sortBy: ({ currency }) => `min_max_position_${currency}`,
  // },
  {
    title: "Bids\u00A0Vs\u00A0Asks\n(1%\u00A0Depth)",
    // sortBy: "buy_div_sell_4",
    props: { width: 185 },
  },
  {
    title: "Price Chart",
    styles: { padding: "16px 0" },
  },
];

export const sortColumns = extractSortColumns(titles);
