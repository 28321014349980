import NextLink from "next/link";
import { Tab as BaseTab, Link } from "@material-ui/core";
import { makeStyles } from "@lib/themes";
import { ITheme } from "@lib/themes/types";

const useTabStyles = makeStyles((theme: ITheme) => ({
  root: {
    fontSize: 20,
    lineHeight: "32px",
    padding: "0px 0px 8px",
    textTransform: "none",
    fontWeight: 600,
    minHeight: 40,
    minWidth: "unset",
    color: theme.palette.tab.text,
    whiteSpace: "nowrap",
    transition: "color .2s ease-in",
    "&:hover": {
      color: theme.palette.tab.textHover,
      opacity: 1,

      "&::after": {
        width: 8,
      },
    },

    "&.Mui-selected": {
      color: theme.palette.tab.textSelected,

      "&::after": {
        width: "calc(100% - 16px)",
        background: theme.palette.tab.borderSelected,
      },

      "&:hover": {
        color: theme.palette.tab.textSelectedHover,
        "&::after": {
          width: "calc(100% - 20px)",
          background: theme.palette.tab.borderSelectedHover,
        },
      },
    },

    "&:not(:first-of-type)": {
      marginLeft: theme.spacing(5),
    },

    "&:first-of-type": {
      marginLeft: theme.spacing(2),
    },

    "&::after": {
      content: "''",
      height: 4,
      width: 4,
      background: theme.palette.tab.border,
      borderRadius: 2,
      position: "absolute",
      bottom: 0,
      left: "50%",
      transform: "translateX(-50%)",
      transition: "width .2s ease-in",
    },
  },
  selected: {},
}));

export default function Tab(props) {
  const tabClasses = useTabStyles();
  return <BaseTab classes={tabClasses} disableRipple {...props} />;
}

export function TabLink(props) {
  const { href, passHref, as, shallow, ...other } = props;
  return (
    <NextLink href={href} passHref={passHref} as={as} shallow={shallow}>
      <Link href={href} style={{ textDecoration: "none" }}>
        <div {...other} />
      </Link>
    </NextLink>
  );
}
